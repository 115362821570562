/* eslint-disable import/prefer-default-export */
import { useCallback, useEffect } from "react";
export default (function (eventName, handler) {
  var wrappedHandler = useCallback(function (event) {
    console.log("Calling custom event -- ", eventName, event);
    handler(event);
  }, [eventName, handler]);
  useEffect(function () {
    window.addEventListener(eventName, wrappedHandler);
    return function () {
      return window.removeEventListener(eventName, wrappedHandler);
    };
  }, [eventName, wrappedHandler]);
});