/* global analytics */
function trackEvent() {
  var trackingEvent = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : "";
  var trackingProps = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var nextUrl = arguments.length > 2 ? arguments[2] : undefined;
  var nextUrlTarget = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : "_self";

  var OpenURLIfSameTab = function OpenURLIfSameTab() {
    if (nextUrlTarget !== "_blank") {
      if (nextUrl) window.open(nextUrl, nextUrlTarget);
    }
  }; // If new tab open in new tab and track in background in the current tab


  if (nextUrl && nextUrlTarget === "_blank") {
    window.open(nextUrl, nextUrlTarget);
  } // If no tracking event is specified, don't track.


  if (trackingEvent !== "") {
    try {
      analytics.track("mba-menubar ".concat(trackingEvent), trackingProps, {}, OpenURLIfSameTab); // backup if tracking takes an age

      setTimeout(OpenURLIfSameTab, 500);
    } catch (err) {
      OpenURLIfSameTab();
    }
  } else {
    OpenURLIfSameTab();
  }
}

export default trackEvent;