function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

/* eslint-disable import/prefer-default-export */
import { useCallback, useState } from "react";
import { HS_CHATFLOWS } from "../constants"; // Chatflows in HubSpot are configured to open based on url targeting.
//
// https://developers.hubspot.com/docs/api/conversations/open-to-chatflow
//
// To open a specific chatflow, we need to update the query string,
// so that HS's targeting for that chatflow will match,
// then refresh the widget.
//
// The important thing here is to ensure that only the most recently opened chatflow actually opens.
// We need to know all possible chatflow query params,
// so we can make sure we're setting all other chatflow query params to 0 when we open a new chat.

var setChatflowQueryString = function setChatflowQueryString(specificChatflow) {
  var url = new URL(window.location);
  HS_CHATFLOWS.forEach(function (possibleChatflow) {
    if (url.searchParams.has(possibleChatflow)) url.searchParams.delete(possibleChatflow);
  });

  if (specificChatflow) {
    url.searchParams.set(specificChatflow, 1);
  }

  window.history.pushState({}, "", url);
};

var useSpecificChatflow = function useSpecificChatflow() {
  var _useState = useState(),
      _useState2 = _slicedToArray(_useState, 2),
      chatflow = _useState2[0],
      setChatflowState = _useState2[1];

  var setChatflow = useCallback(function (specificChatflow) {
    var newChatflow = specificChatflow;

    if (newChatflow && !HS_CHATFLOWS.includes(newChatflow)) {
      console.error("Couldnt open chatflow \"".concat(newChatflow, "\". Falling back to default chatflow"));
      newChatflow = undefined;
    }

    setChatflowQueryString(newChatflow);
    setChatflowState(newChatflow);
  }, []);
  return {
    chatflow: chatflow,
    setChatflow: setChatflow
  };
};

export default useSpecificChatflow;