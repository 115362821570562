/* eslint-disable import/prefer-default-export */
import { useCallback } from "react";
import { useEventListener } from "../../../../hooks";
import { HS_CHATFLOW_PRIVATE_ENQUIRY } from "../constants";
import trackEvent from "../../../../tracking";
import { EVENT_PRIVATE_ENQUIRY } from "../../constants";
export var usePrivateEnquiryListener = function usePrivateEnquiryListener(hsConversationsSDKReady, openChat) {
  var handlePrivateEnquiry = useCallback(function (event) {
    var productName = event.detail && event.detail.productName;
    trackEvent("HANDLE_PRIVATE_ENQUIRY - Intercom", {
      SDK_ready: hsConversationsSDKReady
    });

    if (hsConversationsSDKReady) {
      openChat(HS_CHATFLOW_PRIVATE_ENQUIRY);
    } else {
      var emailTo = "hello@muchbetteradventures.com";
      var subject = productName ? "Private Group Enquiry: ".concat(productName) : "Private Group Enquiry";
      window.open("mailto:".concat(emailTo, "?cc=").concat(emailTo, "&subject=").concat(subject), "_blank").focus();
    }
  }, [hsConversationsSDKReady, openChat]);
  useEventListener(EVENT_PRIVATE_ENQUIRY, handlePrivateEnquiry);
};