function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

/*

The HubSpotConversations SDK object is created and loaded via the tracking code,
which is loaded via segment.

That means, even if we don't mount the HubSpotLiveChat component here,
it's possible that the chat could auto-initialise via the tracking code and auto open itself.

If we want to take complete control over when the chat opens,
we need to make sure this is set on the window as early as possible in the page render,

@see https://developers.hubspot.com/docs/api/conversation/chat-widget-sdk

*/
var usePreventHubSpotLiveChatLoadImmediately = function usePreventHubSpotLiveChatLoadImmediately() {
  if (typeof window !== "undefined") {
    window.hsConversationsSettings = _objectSpread(_objectSpread({}, window.hsConversationsSettings), {}, {
      loadImmediately: false
    });
  }
};

export default usePreventHubSpotLiveChatLoadImmediately;