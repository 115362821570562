/* global ALGOLIA_APP_ID ALGOLIA_PUBLIC_API_KEY ALGOLIA_PRODUCT_INDEX */

import React from "react";
import ReactDOM from "react-dom";
import ReactDOMServer from "react-dom/server";

import { MenuBar } from "@muchbetteradventures/menubar-lib";

const menuItems = typeof window !== "undefined"
  ? window.MENU_ITEMS
  : { activities: [], menu: [] };

class Main extends React.Component {
  // Remove the server-side injected CSS.
  componentDidMount() {
    const jssStyles = document.getElementById("ssr-mba-menubar-jss");
    if (jssStyles && jssStyles.parentNode) {
      jssStyles.parentNode.removeChild(jssStyles);
    }
  }

  render() {
    const { knowledgeBaseUrl } = this.props;

    return (
      <MenuBar
        menu={menuItems.menu}
        activities={menuItems.activities}
        algoliaAppId={ALGOLIA_APP_ID}
        algoliaPublicApiKey={ALGOLIA_PUBLIC_API_KEY}
        algoliaProductIndex={ALGOLIA_PRODUCT_INDEX}
        knowledgeBaseUrl={knowledgeBaseUrl}
        displayIcons
      />
    );
  }
}

// Render only in the browser, export otherwise
if (typeof document === "undefined") {
  const html = ReactDOMServer.renderToString(
    <MenuBar menu={menuItems.menu} activities={menuItems.activities} />,
  );

  process.stdout.write(`<div id="mba-menubar-container">${html}</div>`);
} else {
  const el = document.getElementById("mba-menubar-container");
  if (el) {
    ReactDOM.render(
      <Main knowledgeBaseUrl={el.dataset.knowledgeBaseUrl} />,
      el,
    );
  }
}
