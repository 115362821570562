/* eslint-disable import/prefer-default-export */
import { useCallback } from "react";
import { useEventListener } from "../../../../hooks";
import trackEvent from "../../../../tracking";
import { EVENT_SUPPORT_NEEDED } from "../../constants";
export var useSupportNeededListener = function useSupportNeededListener(knowledgeBaseUrl, hsConversationsSDKReady, openChat) {
  var handleSupportNeeded = useCallback(function () {
    trackEvent("HANDLE_SUPPORT_NEEDED - Hubspot", {
      SDK_ready: hsConversationsSDKReady
    });

    if (hsConversationsSDKReady) {
      openChat();
    } else {
      window.open(knowledgeBaseUrl, "_blank").focus();
    }
  }, [knowledgeBaseUrl, hsConversationsSDKReady, openChat]);
  useEventListener(EVENT_SUPPORT_NEEDED, handleSupportNeeded);
};