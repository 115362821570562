/* eslint-disable import/prefer-default-export */
import axios from "./axiosinstance";
var USER_CURRENCY_ENDPOINT = "/api/v3/accounts/profile/currency/";
var RATES_ENDPOINT = "/api/v2/forex/rates/";
export var getUserCurrencyCode = function getUserCurrencyCode() {
  var userCurrencyEndpoint = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : USER_CURRENCY_ENDPOINT;
  return axios.get(userCurrencyEndpoint, {
    withCredentials: true
  }).then(function (response) {
    return response.data;
  }).catch(function (error) {
    console.warn("Failed getting user currency code in menubar. ", error.message, error);
  });
};
export var getRates = function getRates() {
  var ratesEndpoints = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : RATES_ENDPOINT;
  return axios.get(ratesEndpoints, {
    withCredentials: true
  }).then(function (response) {
    return response.data;
  }).catch(function (error) {
    console.warn("Failed getting fx rates in menubar. ", error.message, error);
  });
};