/* eslint-disable import/prefer-default-export */
import { useCallback } from "react";
import { useEventListener } from "../../../../hooks";
import { HS_CHATFLOW_MORE_PRODUCT_INFORMATION } from "../constants";
import trackEvent from "../../../../tracking";
import { EVENT_MORE_PRODUCT_INFORMATION } from "../../constants";
export var useMoreProductInformationListener = function useMoreProductInformationListener(hsConversationsSDKReady, openChat) {
  var handleMoreProductInformation = useCallback(function (event) {
    var productName = event.detail && event.detail.productName;
    trackEvent("HANDLE_MORE_PRODUCT_INFORMATION - Hubspot", {
      SDK_ready: hsConversationsSDKReady
    });

    if (hsConversationsSDKReady) {
      openChat(HS_CHATFLOW_MORE_PRODUCT_INFORMATION);
    } else {
      var emailTo = "hello@muchbetteradventures.com";
      var subject = productName ? "Product Information Request: ".concat(productName) : "Product Information Request";
      window.open("mailto:".concat(emailTo, "?cc=").concat(emailTo, "&subject=").concat(subject), "_blank").focus();
    }
  }, [hsConversationsSDKReady, openChat]);
  useEventListener(EVENT_MORE_PRODUCT_INFORMATION, handleMoreProductInformation);
};