function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import { useCallback } from "react";
export default (function (currentUser, token) {
  var loadWidget = useCallback(function () {
    if (currentUser && token) {
      window.hsConversationsSettings = _objectSpread(_objectSpread({}, window.hsConversationsSettings), {}, {
        identificationEmail: currentUser.email,
        identificationToken: token
      });
    }

    window.HubSpotConversations.widget.load();
  }, [currentUser, token]);
  var removeWidget = useCallback(function () {
    window.HubSpotConversations.widget.remove();
  }, []);
  var refreshWidget = useCallback(function () {
    var newThread = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
    var status = window.HubSpotConversations.widget.status();

    if (status.loaded) {
      window.HubSpotConversations.widget.refresh({
        openToNewThread: newThread
      });
    }
  }, []);
  return {
    loadWidget: loadWidget,
    removeWidget: removeWidget,
    refreshWidget: refreshWidget
  };
});